import {AccountModel} from "../models/AccountModels";
import {DepositModel} from "../models/DepositModels";
import {Alert, Button, Center, Group, Input, Space, TextInput} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {PayCcAsync} from "../services/PaymentService";
import {IconAlertCircle} from "@tabler/icons-react";

interface Props {
    // account: AccountModel
    deposit: DepositModel
    // handleSetAsTimeoutAsync: () => void
}

export const CcPay = ({deposit}: Props) => {
    const [cardHolder, setCardHolder] = useState<string>("")
    const [cardNumber, setCardNumber] = useState<string>("")
    const [cvv, setCvv] = useState<string>("")
    const [expMonth, setExpMonth] = useState<string>("")
    const [expYear, setExpYear] = useState<string>("")
    const [amount, setAmount] = useState<number>(50)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errMessage, setErrMessage] = useState<string>("")
    useEffect(() => {

    }, [])
    const handlePay = () => {
        setIsLoading(true)
        PayCcAsync({
            cardNumber: cardNumber,
            holderName: cardHolder,
            cvv: cvv,
            amount: deposit.amount,
            expireYear: expYear,
            expireMonth: expMonth,
            depositId: deposit.id
        }).then(res => {
            if (res.success) {
                if (res.data.success)
                    window.location.replace(res.data.redirect_url)
                else
                    setErrMessage(res.message);
            } else {
                setErrMessage(res.message);
            }
        }).catch(err => {

        }).finally(() => setIsLoading(false))
    }
    return (
        <>

            <Input.Wrapper
                // withAsterisk
                label="Kart Sahibi"
            >
                <Input onChange={(e) => setCardHolder(e.target.value)}/>
            </Input.Wrapper>
            <Space h={20}/>
            <Input.Wrapper
                // withAsterisk
                label="Kart Numarası"
            >
                <Input
                    onChange={(e) => setCardNumber(e.target.value)}
                    placeholder="Your email"/>
            </Input.Wrapper>
            <Space h={20}/>
            <Center>
                <Group w={'100%'}>
                    <Input.Wrapper
                        // withAsterisk
                        w={"48%"}
                        label="Skt Yıl"
                    >
                        <Input onChange={(e) => setExpYear(e.target.value)}/>
                    </Input.Wrapper>

                    {/*<Space h={20}/>*/}
                    <Input.Wrapper
                        // withAsterisk
                        w={"48%"}
                        label="Skt Ay"
                    >
                        <Input onChange={(e) => setExpMonth(e.target.value)}/>
                    </Input.Wrapper>
                </Group>
            </Center>
            <Space h={20}/>
            <Group w={'100%'}>


                <Input.Wrapper
                    // withAsterisk
                    label="CVV"
                    w={"48%"}
                >
                    <Input onChange={(e) => setCvv(e.target.value)}/>
                </Input.Wrapper>
                {/*<Space h={20}/>*/}
                <Input.Wrapper
                    // withAsterisk
                    label="Tutar"
                    w={"48%"}
                    description="Please enter your credit card information, we need some money"
                    error="Your credit card expired"
                >
                    <Input type={"number"} onChange={(e) => setAmount(Number(e.target.value))}/>
                </Input.Wrapper>
            </Group>
            <Space h={20}/>
            <Center>
                <Button fullWidth disabled={isLoading} loading={isLoading} onClick={() => handlePay()}
                    // disabled={!(selectedBankName?.length > 0)}
                >Ödemeye Geç</Button>
            </Center>
            <Space h={20}/>
            {errMessage.length>0 ? (
                <Alert icon={<IconAlertCircle size="1rem"/>} title="Hata!" color="red">
                    {errMessage}
                </Alert>
            ) : <></>}
        </>
    )
}
