import axios, {AxiosRequestConfig} from "axios";
import {decryptData} from "./encryptionHelper";

export const getFromLocalStorage = (key: string) => {
    if (!key || typeof window === 'undefined') {
        return ""
    }
    return localStorage.getItem(key)
}


export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/api/",
    //baseURL: 'http://localhost:5141/api/',
    timeout: 45000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': ''
    }
});

export const apiRequest = async <T>(config: AxiosRequestConfig): Promise<T> => {
    try {
        const response = await api(config);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
