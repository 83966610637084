import {Center, Paper, Space, Text} from "@mantine/core";
import {IconCircleCheck} from "@tabler/icons-react";
import React from "react";

export const AwaitingConfirmationMsgComponent =()=>{
    return <>
        <Center>
            <IconCircleCheck
                size={120}
                strokeWidth={0.5}
                color={'#40bf51'}
            />
        </Center>
        <Space h="xl"/>
        <Space h="xl"/>
        <Space h="xl"/>
        <Paper shadow="lg" radius="md" p="lg">
            <Center>
                <Text>
                    Talebiniz Alındı. Yatırım İşlemi Onaylandığında Siteye
                    Yönlendirileceksiniz.
                </Text>
            </Center>
        </Paper>
    </>
}