import {Center, Paper, Space, Text} from "@mantine/core";
import {IconCircleCheck} from "@tabler/icons-react";
import React from "react";

export const DeclinedMsgComponent =()=>{
    return <>
        <Center>
            <IconCircleCheck
                size={120}
                strokeWidth={0.5}
                color={'red'}
            />
        </Center>
        <Space h="xl"/>
        <Space h="xl"/>
        <Space h="xl"/>
        <Paper shadow="lg" radius="md" p="lg">
            <Center>
                <Text c={"red"}>
                    İşleminiz Başarısız Sonuçlandı. Siteye Yönlendiriliyorsunuz...
                </Text>
            </Center>
        </Paper>
    </>
}