import {AccountCreateModel, AccountModel} from "../models/AccountModels";
import {AxiosRequestConfig} from "axios";
import {apiRequest} from "./index";
import {DepositModel, DepositSetAccModel, DepositSetAsPaidModel} from "../models/DepositModels";
import {ApiDataResponse, ApiResponse, LoginModel, LoginResponseModel} from "../models/AuthModels";
import {CcPayRequestModel, CcPayResponseModel} from "../models/ApiModels";

export const getDeposit = async (token: string): Promise<ApiDataResponse<DepositModel>> => {
    const config: AxiosRequestConfig = {
        // headers: {
        //     'Authorization': `Bearer ${await loginAsync()}`
        // },
        method: 'GET',
        url: `/deposits/get-by-payment-token?token=${token}`,
    };
    return await apiRequest<ApiDataResponse<DepositModel>>(config);
};
export const getAvailableAccounts = async (token: string): Promise<ApiDataResponse<AccountModel[]>> => {
    const config: AxiosRequestConfig = {
        // headers: {
        //     'Authorization': `Bearer ${await loginAsync()}`
        // },
        method: 'GET',
        url: `/Accounts/available-accounts-for-payment?paymentToken=${token}`,
    };
    return await apiRequest<ApiDataResponse<AccountModel[]>>(config);
};

export const DepositSetAccountAsync = async (setAccModel: DepositSetAccModel): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        method: 'POST',
        // headers: {
        //     'Authorization': `Bearer ${await loginAsync()}`
        // },
        url: '/deposits/deposit-request-set-account',
        data: JSON.stringify({...setAccModel, securityKey: process.env.REACT_APP_PAYMENT_SECURITY_KEY})
    };
    return await apiRequest<ApiResponse>(config);
}
export const DepositSetAsPaidAsync = async (setAsPaidModel: DepositSetAsPaidModel): Promise<ApiResponse> => {

    const config: AxiosRequestConfig = {
        method: 'POST',
        url: '/deposits/deposit-request-set-as-paid',
        data: JSON.stringify({...setAsPaidModel, securityKey: process.env.REACT_APP_PAYMENT_SECURITY_KEY})
    };
    return await apiRequest<ApiResponse>(config);
}
export const DepositSetAsTimeoutAsync = async (setAsPaidModel: DepositSetAsPaidModel): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        // headers: {
        //     'Authorization': `Bearer ${await loginAsync()}`
        // },
        method: 'POST',
        url: '/deposits/deposit-request-set-as-timeout',
        data: JSON.stringify({...setAsPaidModel, securityKey: process.env.REACT_APP_PAYMENT_SECURITY_KEY})
    };
    return await apiRequest<ApiResponse>(config);
}
export const DepositSetAsFailedAsync = async (setAsPaidModel: DepositSetAsPaidModel): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        method: 'POST',
        // headers: {
        //     'Authorization': `Bearer ${await loginAsync()}`
        // },
        url: '/deposits/deposit-request-set-as-failed',
        data: JSON.stringify(setAsPaidModel)
    };
    return await apiRequest<ApiResponse>(config);
}

export const DeleteAccountAsync = async (id: number): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        method: 'POST',
        url: '/accounts/delete-account',
        data: JSON.stringify({id: id})
    };
    return await apiRequest<ApiResponse>(config);
};
export const PayCcAsync = async (model: CcPayRequestModel): Promise<ApiDataResponse<CcPayResponseModel>> => {
    const config: AxiosRequestConfig = {
        method: 'POST',
        // headers: {
        //     'Authorization': `Bearer ${await loginAsync()}`
        // },
        url: '/payments/cc-payment',
        data: JSON.stringify(model)
    };
    return await apiRequest<ApiDataResponse<CcPayResponseModel>>(config);
};


export const loginAsyncOLDDD = async (): Promise<string> => {
    const config: AxiosRequestConfig = {
        method: 'POST',
        url: '/auth/login',
        data: JSON.stringify({
            username: process.env.REACT_APP_USERNAME as string,
            password: process.env.REACT_APP_PW as string
        })
    };
    var result = await apiRequest<ApiDataResponse<LoginResponseModel>>(config);
    console.log(result)
    return result.data?.token;
}