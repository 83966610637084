import {DepositModel} from "../models/DepositModels";
import {Space, Text} from "@mantine/core";
import React from "react";
import {amountFormatter} from "../App";

interface Props{
    data:DepositModel
}
export const DepositInfoComponent = ({data}:Props)=>{
    return <>
        <Space h="xl"/>
        <Text
            variant="gradient"
            gradient={{from: 'indigo', to: 'cyan', deg: 45}}
            sx={{fontFamily: 'Greycliff CF, sans-serif'}}
            ta="center"
            fz="xl"
            fw={700}
        >
            {data.customerFullName}
        </Text>
        <Text align={'center'}>{amountFormatter.format(data.amount)}</Text>
        <Space h="xl"/>
    </>
}